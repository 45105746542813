import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero"
import ProductListing from "../../components/lists/ProductListing"
import InlineBreaker from "../../components/lists/InlineBreaker/InlineBreaker"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const inlineBreakers = useStaticQuery(graphql`{
  allProduktListenStoererHJson(
    filter: {anzeige: {elemMatch: {pfad: {eq: "produkte/textilien"}}}}
  ) {
    edges {
      node {
        button {
          link
          text
        }
        kurzbeschreibung
        title
        icon {
          alt
          ratio
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        anzeige {
          pfad
          positionen
        }
      }
    }
  }
}
`);

    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "Produkte", url: "produkte" },
                { label: "Textilien", url: "textilien" },
            ]}
            breadcrumbColor="light"
            headerColor="dark-blue"
            hasWave={false}
        >
            <Hero
                title="Unsere Textilien"
                subtitle="Unser textiles Angebot reicht von Bekleidung wie T-Shirts, Polos bis hin zu Pullover für Kinder, Frauen und Männer. Wir bieten hier nur qualitativ hochwertige Produkte an. Diese Qualität spiegelt sich im Textil selbst, sowie auch im Druck wieder. Für die vollste Zufriedenheit unserer Kunden!"
                color="dark-blue"
                hasWaveBottom={["mobile"]}
            />

            <ProductListing 
                hasFilters={true} 
                filterByType="textil"
                inlineBreaker={inlineBreakers.allProduktListenStoererHJson.edges.map(({node}) => (
                    {
                        element: <InlineBreaker 
                            to={node.button.link} 
                            buttonText={node.button.text} 
                            title={node.title} 
                            kurzbeschreibung={node.kurzbeschreibung} 
                            icon={{src: node.icon.src, alt: node.icon.alt, ratio: node.icon.ratio}} 
                        />,
                        colspan: 2,
                        position: node.anzeige.filter(item => item.pfad === "produkte/textilien")[0].positionen,
                    }))
                }
            />
        </Layout>
    )
}
